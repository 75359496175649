import React from "react"
import './styles/footer.scss'
import {useHistory} from "react-router-dom"

//icon
import icTicket from '../../../../assets/new-icons/ic-ticket-footer.svg'
import icStar from '../../../../assets/new-icons/ic-start-footer.svg'
import icQuestion from '../../../../assets/new-icons/ic-question-footer.svg'
import icTicketActive from '../../../../assets/new-icons/ic-ticket-footer-active.svg'
import icStarActive from '../../../../assets/new-icons/ic-star-footer-active.svg'
import icQuestionActive from '../../../../assets/new-icons/ic-question-footer-active.svg'

const footerList = [
    {
        id: 1,
        label: 'Đặt vé',
        url: '/',
        ic_url: icTicket,
        ic_active_url: icTicketActive
    },
    {
        id: 2,
        label: 'Kết quả',
        url: '/result-lottery',
        ic_url: icStar,
        ic_active_url: icStarActive
    },
    {
        id: 3,
        label: 'FAQs',
        url: '/FAQs',
        ic_url: icQuestion,
        ic_active_url: icQuestionActive
    }
]
function Footer(props) {
    const history = useHistory()
    return (
        <footer className="container-footer">
                {
                    footerList?.map((item, index) => {
                        return(
                            <div className={`container-footer__item ${item?.url === props?.pathname ? 'active' : ''}`} key={index} onClick={() => history.push(item?.url)}>
                                <div className={'background-image footer-icon'} style={{backgroundImage: `url('${item?.url === props?.pathname ? item?.ic_active_url : item?.ic_url}')`}}/>
                                <p className={'footer-label'}>{item?.label}</p>
                            </div>
                        )
                    })
                }
        </footer>
    )
}
export default Footer