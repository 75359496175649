import Header from "./components/Header/Header"
import React from "react"
function Page(props) {
    return (
        <>
            <Header isHideItemRight={props?.isHideItemRight} headerTitle={props?.headerTitle} backToHome={props?.backToHome} isShowMenu={props?.isShowMenu} isShowNotification={props?.isShowNotification} isShowShopCart={props?.isShowShopCart} isShowTrash={props?.isShowTrash} isShowSchedule={props?.isShowSchedule} handleRemove={() => props?.handleRemove()} handleShowNotification={() => props.handleShowNotification()} showNotify={props?.showNotify}/>
            <div style={{paddingBottom: '48px'}}/>
            {
                props?.children
            }
        </>
    )
}
export default Page