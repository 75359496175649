import Page from "../../components/Page/Page";
import React, {useState, useEffect, useRef} from "react"
import {notification, Tabs} from "antd";
import { injectIntl } from 'react-intl'
import './styles/refer-friends.scss'
import IntroductionBoard from "../../components/IntroductionBoard";
import icCoppy from "../../assets/new-icons/ic-coppy.svg"
import imgNodata from "../../assets/new-images/img-nodata.png"
import icCalendar from "../../assets/new-icons/ic-calendar-red.svg"
import {useUser} from "../../context/UserContext";
import {routes} from "../../App";
import {useHistory} from "react-router-dom";
import Statistical from "../../services/statistical"
import PaymentDepositTransaction from "../../services/paymentDepositTransaction";
import {convertTimeDate} from "../../ultils/convertDate"
import currencyFormat from "../../ultils/CurrencyFormat"
import Loading from "../../components/Loading";

function ReferFriends(props) {
    const {intl} = props
    const {TabPane} = Tabs
    const date = new Date()
    const {user} = useUser();
    const[data, setData] = useState({})
    const[dataHistory, setDataHistory] = useState([])
    const[dataParty, setDataParty] = useState([])
    const [skip, setSkip] = useState(0)
    const [skip1, setSkip1] = useState(0)
    const [number, setNumber] = useState(0)
    const history= useHistory()
    const [isLoading, setIsLoading] = useState(true)

    const onChange = () => {
    }

    function handleCopyUrl (value) {
        let textField = document.createElement('textarea')
        textField.innerText = value
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        notification.open({
            message: 'Thông báo',
            description:
                'Copy thành công!!!'
        })
    }
    useEffect(() => {
        const data = {
            skip: skip,
            limit: 10
        }
        userSummaryReferUser(data)
    }, [skip])
    function userSummaryReferUser(data) {
        Statistical.userSummaryReferUser(data).then(r => {
            const {isSuccess, data} = r
            if (isSuccess) {
                setData(data)
                dataParty.push(...data?.data)
                setDataParty([...dataParty])
            } else {
            }
            if(isLoading){
                setIsLoading(false)
            }
        })
    }
    useEffect(() => {
        const data = {
            skip: skip1,
            limit: 10
        }
        bonusHistory(data)
    }, [skip1])
    function bonusHistory(data) {
        PaymentDepositTransaction.bonusHistory(data).then(r => {
            const {isSuccess, data} = r
            if (isSuccess) {
                setNumber(data?.data?.length)
                dataHistory.push(...data?.data)
                setDataHistory([...data?.data])
            }
        })
    }

    function handleChangeSkip(){
        setSkip(skip + 10)
    }
    function handleChangeSkip1(){
        setSkip1(skip + 10)
    }
    return (
        <Page isShowSchedule={true} headerTitle={intl.formatMessage({id: 'refer_friends'})}>
            <div id="ReferFriends">
                {
                    isLoading &&
                    <Loading/>
                }
                {
                    !isLoading &&
                    <Tabs defaultActiveKey="1" onChange={onChange} className={'overview'}>
                        <TabPane tab="Tổng quan" key="1">
                            <div className="overview-container">
                                <div className="overview-container-info">
                                    <div className="overview-container-info-id">
                                        <div className="left">Mã giới thiệu bạn bè</div>
                                        <div className="right">
                                            <div className="right-red">{user.referCode}</div>
                                            <div className="right-img background-image" onClick={() => {handleCopyUrl(user.referCode)}} style={{backgroundImage: `url(${icCoppy})`}}/>
                                        </div>
                                    </div>
                                    <div className="overview-container-info-link">
                                        <div className="left" style={{padding: '12px 0 8px 0'}}>Link giới thiệu</div>
                                        <div className="right">
                                            <div className="right-blue">{user.referLink}</div>
                                            <div className="right-img background-image" onClick={() => {handleCopyUrl(user.referLink)}} style={{backgroundImage: `url(${icCoppy})`}}/>
                                        </div>
                                    </div>
                                    <div className="overview-container-info-qr" style={{backgroundImage: `url(https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${user.referLink})`}}/>
                                </div>
                            </div>
                            <div className="overview-container">
                                <div className="overview-container-info">
                                    <div className="rose">Doanh thu hoa hồng</div>
                                    <div className="overview-container-info-content">
                                        <div className="overview-container-info-content-line">
                                            <div className="rose-left">Hoa hồng ngày hôm qua</div>
                                            <div className="right right-red">{currencyFormat(data?.totalBonus?.bonusYesterdayTotal) || 0} đ</div>
                                        </div>
                                        <div className="overview-container-info-content-line">
                                            <div className="left1">Hoa hồng trực tiếp</div>
                                            <div className={"right1"}>{currencyFormat(data?.totalBonus?.bonusYesterdaySystem) || 0} đ</div>
                                        </div>
                                        <div className="overview-container-info-content-line">
                                            <div className="left1">Hoa hồng đội</div>
                                            <div className={"right1"}>{currencyFormat(data?.totalBonus?.bonusYesterdayF1) || 0} đ</div>
                                        </div>
                                    </div>
                                    <div className="overview-container-info-content">
                                        <div className="overview-container-info-content-line">
                                            <div className="left1">Tổng doanh thu</div>
                                            <div className={"right1 right-red"}>{currencyFormat(data?.totalBonus?.totalSystemBuy || 0)} đ</div>
                                        </div>
                                        <div className="overview-container-info-content-line">
                                            <div className="left1">Số người đã đăng kí</div>
                                            <div className={"right1"}>{currencyFormat(data?.totalMember?.totalSystem || 0)}</div>
                                        </div>
                                    </div>
                                    <div className="overview-container-info-content-line">
                                        <div className="left1">Tổng hoa hồng</div>
                                        <div className={"right1 right-red"}>{currencyFormat(user?.wallets?.find(wallet => wallet.walletType === `${props?.type || 'BonusWallet'}`).balance || 0)} đ</div>
                                    </div>
                                    <div className="overview-container-info-content-line">
                                        <div className="left1">Hoa hồng có thể nhận</div>
                                        <div className={"right1"}>{currencyFormat(user?.wallets?.find(wallet => wallet.walletType === `${props?.type || 'BonusWallet'}`).balance || 0)} đ</div>
                                    </div>
                                    <div className="overview-container-info-btn" onClick={() => history.push(routes.commissionPayment.path)}>Thanh toán hoa hồng</div>
                                </div>
                            </div>
                            <IntroductionBoard />
                        </TabPane>
                        <TabPane tab="Đội của tôi" key="2">
                            <div className="team-title">Đội trực tiếp ({data?.total} người)</div>
                            <div className="calendar">
                                <div className="calendar-img" style={{backgroundImage:`url(${icCalendar})`}}/>
                                <div className="calendar-text">Ngày {date.getDate()} tháng {date.getMonth() + 1} năm {date.getFullYear()}</div>
                            </div>
                            <div style={{padding:"0px 8px"}}>Tổng số tiền đặt vé: <b>{data?.totalDeposit} đ</b></div>
                            <div style={{padding:"8px 8px 16px"}}>Tổng số tiền hoa hồng: <b>{data?.totalBonus?.bonusTotal} đ</b></div>
                            <div className="history">
                                <table>
                                    <thead>
                                    <tr className="history-title">
                                        <td>Tài khoản</td>
                                        <td>Mua</td>
                                        <td>Hoa hồng</td>
                                    </tr>
                                    </thead>
                                    {
                                        dataParty?.length > 0 &&
                                        <tbody>
                                        {
                                            dataParty?.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{value?.username}</td>
                                                        <td>{currencyFormat(value?.totalSell || 0)}</td>
                                                        <td>{currencyFormat(value?.totalDeposit || 0)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    }
                                </table>
                                {
                                    data?.data?.length === 10 &&
                                    <div style={{width:"100%", textAlign:"center", cursor:"pointer"}} onClick={() => {handleChangeSkip()}}>Xem thêm...</div>
                                }
                                {
                                    dataParty?.length === 0 &&
                                    <div style={{padding:"8px"}}>
                                        <div className="nodata" style={{backgroundImage: `url(${imgNodata})`}}/>
                                        <div style={{textAlign:"center"}}>Không có dữ liệu</div>
                                    </div>
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Lịch sử nhận" key="3">
                            <div className="history">
                                <table>
                                    <thead>
                                    <tr className="history-title">
                                        <td>Thời gian</td>
                                        <td>Số tiền</td>
                                        <td>Trạng thái</td>
                                    </tr>
                                    </thead>
                                    {
                                        dataHistory.length > 0 &&
                                        <tbody>
                                        {
                                            dataHistory.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{convertTimeDate(value.createdAt)}</td>
                                                        <td>{currencyFormat(value.paymentAmount)} {value.paymentUnit}</td>
                                                        <td>{value.paymentStatus}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>

                                    }
                                </table>
                                {
                                    number === 10 &&
                                    <div style={{width:"100%", textAlign:"center", cursor:"pointer"}} onClick={() => {handleChangeSkip1()}}>Xem thêm...</div>
                                }
                                {
                                    dataHistory.length === 0 &&
                                    <div style={{padding:"8px"}}>
                                        <div className="nodata" style={{backgroundImage: `url(${imgNodata})`}}/>
                                        <div style={{textAlign:"center"}}>Không có dữ liệu</div>
                                    </div>
                                }

                            </div>
                        </TabPane>
                        <TabPane tab="Hướng dẫn" key="4">
                            <IntroductionBoard />
                        </TabPane>
                    </Tabs>
                }
            </div>
        </Page>
    )
}
export default injectIntl(ReferFriends)