
import React, { useEffect } from 'react'
import "./index.scss"
import Footer from "../../components/Page/components/Footer/Footer"
import {useHistory} from "react-router-dom"

function LayoutPage(props) {
  const history= useHistory()
  const { isAuth, Component, className = "", isHiddenFooter, isUserLoggedIn} = props
  const { location} = props
  const { pathname = "" } = location

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const headerId = document.getElementById("header-sticky")
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle('sticky')
        } else {
          headerId.classList.remove('sticky')
        }
      }
    })
  }, [])


  useEffect(() => {
    if (isAuth) {
      if (!isUserLoggedIn) {
        history.push('/login')
      }
    }
    if (isUserLoggedIn && (pathname === '/login' || pathname === '/register')) {
      history.push('/')
    }
    if (pathname==='/') {
      document.getElementById('root').style.backgroundColor = '#70B3F9'
    } else if (pathname === '/shop-cart' || pathname === '/payment' || pathname === '/recharge' || pathname === '/withdraw'  || pathname === '/contacts' || pathname === '/refer-friends' || pathname === '/commission-payment' || pathname === '/transition-history' ||pathname==="/FAQs" || pathname==="/booking-history") {
      document.getElementById('root').style.backgroundColor = '#fafafa'
    } else {
      document.getElementById('root').style.backgroundColor = '#fff'
    }
    document.getElementById('root').style.height = '100vh'
  }, [props, isAuth])

  useEffect(() => {
    const bodyId = document.getElementById("body-root")
    if (bodyId) {
      bodyId.classList.add(className || "")
    }
  }, [className])
  return (
    <>
      <div>
        <Component {...props} />
      </div>

      {
        !isHiddenFooter &&
        <Footer pathname={pathname}/>
      }
    </>
  )
}

export default LayoutPage
